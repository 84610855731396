/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/* hide parts of the website in case it is verry narrow */ 
@media only screen and (max-width: 767px) {
  .hide-sm {
    visibility: hidden;
  }
}